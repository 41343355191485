@import "./variable.scss";
@import "./media.scss";

#wholesalepage {
  .heading {
    width: 100%;
    text-align: center;
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;

    h1 {
      color: #006281;
      font-size: clamp(100%, 10vw, 4rem);
      text-align: start;
      font-weight: 600;
      font {
        color: #006281;
      }
    }
  }

  .container1 {
    max-width: 1200px;
    margin: auto;
    padding: 0 15px 90px 15px;
    display: flex;
    flex-direction: column;
  }
}
