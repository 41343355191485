@import "./variable.scss";
@import "./media.scss";

.about {
  width: 100%;
  margin-top: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
  height: 100%;
  overflow: hidden;
  position: relative;

  @include md {
    position: relative;
    height: 100%;
    padding: 0 !important;
  }

  .container {
    position: relative;
    max-width: 1200px;
    margin: auto;
    height: 100%;
    z-index: 6;
    display: flex;
    padding: 90px 15px 90px;
    /* margin-top: 230px; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 230px; */
    /* margin-top: 230px; */

    .heading {
      margin-bottom: 30px;

      h1 {
        color: #006281;
        font-size: clamp(18%, 12vw, 4rem);
        text-align: start;
        font-weight: 600;
        text-transform: none;

        font {
          color: #006281;
        }
      }
    }

    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;
      justify-content: center;

      @include lg {
        grid-template-columns: 1fr 1fr;
      }

      @include mobile {
        grid-template-columns: 1fr;
      }

      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        @include lg {
          margin-bottom: 50px;
          
        }
        @include mobile {
          margin-right: 0px;

          
        }

        .i {
          img {
            width: 100%;
            height: 100%;

            @include md {
              max-width: 120px;
            }
          }
        }

        .content-box {
          margin-top: 20px;
          background: #b2d0d9;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 40px 30px;
          height: 400px;
          max-width: 500px;
          justify-content: start;
          border-radius: 25px;

          @include lg {
            height: 310px;

          }

          @include md {
            height: 350px;
          }

          @include mobile {
            height: 290px;
          }

          h2 {
            color: black;
            font-weight: 900;
            text-transform: capitalize;
            margin-bottom: 20px;

            font {
              color: black;
            }
          }

          p {
            color: black;
            text-align: center;
            font-size: 22px;
            letter-spacing: 0.2px;
            line-height: 1.2em;
            font-weight: 200;

            font {
              color: black;
            }
          }
        }
      }
    }

    .btn {
      display: flex;
      align-items: center;
      margin-top: 40px;
      justify-content: center;

      @include smobile {
        margin-top: 30px;
      }

      a {
        border-radius: 30px;
        padding: 15px 20px;
        background: #006281;
        color: white;
        font-weight: 600;
        font-size: 26px;

        @include lg {
          font-size: 20px;
        }
      }
    }
  }
}