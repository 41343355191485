@import "./variable.scss";
@import "./media.scss";

#aides {
  background-color: rgb(0, 98, 129);
  box-shadow: rgba(12, 0, 46, 0.06) 0px 4px 6px 0px;
  .container {
    position: relative;
    max-width: 1200px;
    margin: auto;
    height: 100%;

    z-index: 6;
    display: flex;
    padding: 90px 15px 90px;
    /* margin-top: 230px; */
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .heading {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100%;
      margin-bottom: 45px;
      justify-content: center;
      
      h1 {
        color: #a2f6e3;
        font-size: clamp(18%, 10vw, 3.5rem);
        text-align: start;
        font-weight: 600;
        text-transform: none;
      }
    }
    .content {
      display: grid;
      grid-template-columns: 1fr 2fr;
      @include md {
        grid-template-columns: 1fr;
 
      }
      .img {
        display: flex;
        align-items: center;
        justify-content: start;
        @include md{
         margin-bottom: 20px;
        }
        img {
          width: 100%;
          max-width: 300px;
          height: auto;
          object-fit: cover;
        }
      }
      img {
        width: 100%;
        max-width: 300px;
        height: auto;
        object-fit: cover;
      }
      .para {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        .btn {
          display: flex;
          align-items: center;
          margin-top: 25px;
          justify-content: start;
          @include smobile {
            margin-top: 30px;
          }
          a {
            border-radius: 30px;
            padding: 15px 20px;
            background: white;
            color: #006281;
            font-weight: 600;
            font-size: 26px;
            font {
              color: #006281;
            }
            @include lg {
              font-size: 20px;
            }
            // @include mobile{
            //   font-size: 18px;
            // }
            // @include smobile{
            //   font-size: 16px;
            // }
          }
        }
        p {
          color: white;
          text-align: start;
          font-size: 25px;
          letter-spacing: 0.2px;
          line-height: 1.4;
          font-weight: 100;
          margin-bottom: 20px;
        }
      }
    }
  }
}
