@import "./variable.scss";
@import "./media.scss";

#footer-wrapper {
  #footer {
    position: relative;

    .container__ {
      padding: 90px 0;

      .heading {
        padding: 0px 15px;
        text-align: start;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        padding: 40px 15px;
        max-width: 1200px;
        margin: auto;

        h1 {
          font-size: clamp(10%, 12vw, 4rem);
          text-align: center;
          color: white;
          text-transform: none;
          margin-bottom: 25px;

          @include lg {
            margin-bottom: 20px;

          }

        }

        h2 {
          font-size: clamp(100%, 10vw, 4rem);
          color: white;
          line-height: 70px;
          text-transform: none;

          text-align: center;

          @include md {
            line-height: 68px;
          }

          @include smobile {
            line-height: 40px;
          }
        }
      }

      .footer-icons {
        display: flex;
        align-items: center;
        z-index: 111;
        position: relative;
        background: white;
        width: 100%;
        padding: 0px 15px;

        justify-content: space-evenly;
        box-shadow: 0 0 30px 5px #0000003b;

        .i {
          img {
            width: 100%;
            height: 100%;
            max-width: 90px;
            padding: 5px 10px;
          }
        }
      }

      .btn {
        display: flex;
        align-items: center;
        margin-top: 50px;
        justify-content: center;

        @include smobile {
          margin-top: 30px;
        }

        a {
          border-radius: 30px;
          padding: 15px 20px;
          background: white;
          color: #006281;
          font-weight: 600;
          font-size: 26px;

          font {
            color: #006281;
          }

          @include lg {
            font-size: 20px;
          }
        }
      }

      .btn__ {
        display: flex;
        align-items: center;
        margin-top: 50px;
        justify-content: center;

        @include smobile {
          margin-top: 30px;
        }

        a {
          border-radius: 30px;
          padding: 15px 20px;
          background: white;
          color: #006281;
          font-weight: 600;
          font-size: 26px;

          font {
            color: #fff;
          }

          @include lg {
            font-size: 20px;
          }
        }
      }
    }
  }
}