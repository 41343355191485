@import "./variable.scss";
@import "./media.scss";

#wholesale {
  .container {
    align-items: center;
    justify-content: space-between;
    // gap: 30px;
    padding: 110px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include md {
      grid-template-columns: 1fr;
    }

    .img {
      display: flex;
      align-items: start;
      justify-content: center;
      height: 100%;
      width: 100%;
padding-right: 30px;
      @include md {
        order: 1 !important;
        align-items: center;
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        display: flex;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
        align-items: center;
        justify-content: center;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      @include md {
        order: 2 !important;
      }

      h2 {
        color: #006281;
        text-transform: capitalize;
        font-size: clamp(18%, 8vw, 3rem);
        text-align: start;
        font-weight: 600;
        margin-bottom: 15px;
        font {
          color: #006281;
        }
      }

      p {
        color: #006281;
        text-align: start;
        font-size: 18px;
        letter-spacing: 0.2px;
        line-height: 1.5em;
        font-weight: 200;
        font {
          color: #006281;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-top: 0px;

        li {
          &:nth-child(1) {
            font-weight: 500;
            font-size: 25px;
            color: #006281;
            margin-bottom: 10px;
            font {
              color: #006281;
            }
          }

          color: #006281;
          text-align: start;
          font-size: 18px;
          letter-spacing: 0.2px;
          line-height: 1.5em;
          font-weight: 200;
          list-style: none;
          font {
            color: #006281;
          }
        }
      }
    }
  }
}
