.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #006281;
  z-index: 10000;
  position: relative;
}

.progress__wrapper {
  width: 290px;
  margin-top: 15px;
}

.loader__logo {
  width: 180px;
}

.css-eglki6-MuiLinearProgress-root {
  height: 15px !important;
  background: rgba(196, 196, 196, 0.2) !important;
}

.css-5xe99f-MuiLinearProgress-bar1 {
  background: #fff !important;
}
