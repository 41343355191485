@import "./variable.scss";
@import "./media.scss";

#borne {
  .container {
    margin: auto;
    display: grid;
    max-width: 1200px;
    grid-template-columns: 1.1fr 2fr;
    align-items: center;
    padding: 90px 15px 90px 15px;
    align-items: center;
    justify-content: center;

    @include md {
      grid-template-columns: 1fr;
    }

    .img {
      margin-right: 30px;
      @include md{
        margin-bottom: 30px;
      margin-right: 0px;
      }
      img {
        width: 100%;
        height: 100%;
        max-width: 600px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: start;
      height: 100%;

      .heading {
        margin-bottom: 10px;
        h1 {
          color: #006281;
          font-size: clamp(18%, 12vw, 3.5rem);
          text-align: start;
          font-weight: 500;
          text-transform: none;
          font {
            color: #006281;
          }
        }
      }

      p {
        color: #006281;
        text-align: start;
        font-size: 22px;
        letter-spacing: 0.2px;
        line-height: 1.2em;
        font-weight: 200;
        font {
          color: #006281;
        }
      }
    }
  }
}
