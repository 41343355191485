@import "./variable.scss";
@import "./media.scss";

#banner-wrapper {
  #banner {
    width: 100%;
    margin-top: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    height: 100%;
    overflow: hidden;
    position: relative;

    .animation__wrap {
      position: relative;
      top: 30%;
      right: -19%;
      transform: rotate(299deg);

      img {
        figure {
          animation: MoveUpDown 2s linear infinite;
          text-align: center;
        }

        &:nth-child(1) {
          top: 0;
          right: 100px;
          max-width: 36px;
          position: absolute;
        }

        &:nth-child(2) {
          top: 120px;
          right: 10%;
          max-width: 20px;
          position: absolute;
        }

        &:nth-child(3) {
          top: 0;
          left: 300px;
          max-width: 15px;
          position: absolute;
        }
      }
    }

    &::before {
      // content: "";
      // background: url(../../public/images/Background/.png) no-repeat center
      // center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      z-index: -2;
    }

    @include md {
      margin-top: 20px;
      position: relative;
      height: 100%;
    }

    .conatiner-wrapper {
      width: 100%;

      .container {
        position: relative;
        max-width: 1200px;
        margin: auto;
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding: 170px 15px 80px;
        z-index: 6;
        align-items: start;
        justify-content: center;

        @include md {
          padding: 160px 15px 80px;
        }

        .heading {
          text-align: start;
          max-width: 1100px;

          h1 {
            font-size: clamp(100%, 14vw, 5.2rem);
            color: white;
            text-transform: none;

            @include md {
              line-height: 1em;
            }
          }
        }
        .banner-btns {
          display: flex;
          align-items: center;
          justify-content: center;

          margin-top: 50px;
          @include md {
            flex-direction: column;

          }
          .btn {
            padding: 15px 20px;
            background: white;
            border-radius: 30px;
            margin-right: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
@include md{
  margin-right: 0px;

&:nth-child(1){
  margin-bottom: 30px;
  margin-right: 0px;

}

}
            @include smobile {
              margin: 0px;
            }

            a {
              color: #006281;
              font-weight: 600;
              font-size: 26px;
              font {
                color: #006281;
              }
              @include lg {
                font-size: 20px;
              }

              // @include mobile{
              //   font-size: 18px;
              // }
              // @include smobile{
              //   font-size: 16px;
              // }
            }
          }
        }
      }
    }
  }
}

.grey {
  color: #006281 !important;
}
