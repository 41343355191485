@import "./variable.scss";
@import "./media.scss";

#form {
  .container {
    max-width: 900px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 30px;
    padding: 130px 15px 90px 15px;

    .heading {
      h1 {
        color: #006281;
        font-size: clamp(18%, 10vw, 4rem);
        text-align: start;
        font-weight: 600;

        font {
          color: #006281;
        }
      }
    }

    .form {
      width: 100%;
    }

    .inputs-field {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      width: 100%;

      .input {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
        margin-bottom: 15px;
        input,
        textarea {
          padding: 8px 5px;
          border: 1px solid #006281;
          outline: none;
          color: black;
          width: 100%;
        }

        span {
          color: #006281;
          font-size: 30px;
          margin-right: 10px;

          font {
            color: #006281;
          }
        }
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
margin: 25px 0;

      p {
        color: #006281;
        text-align: center;
        font-size: 22px;
        letter-spacing: 0.2px;
        line-height: 1.2em;
        font-weight: 200;
        text-align: start;
&:nth-child(1){
  padding-bottom: 5px;
}
        font {
          color: #006281;
        }
      }
    }

    .check {
      display: flex;
      align-items: center;
      justify-content: start;
      color: #006281;
      label {
        color: #006281;
      }
      font {
        color: #006281;
      }

      input {
        padding: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
      }
    }

    .btn {
      display: flex;
      align-items: center;
      margin-top: 35px;
      width: 100%;
      justify-content: center;

      button {
        border-radius: 20px;
        text-transform: uppercase;
        padding: 15px 35px;
        background: #006281;
        color: white;
        font-weight: 600;
        font-size: 24px;
      }
    }
  }
}

.map {
  background: #f7f7f7;

  .container__ {
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @include md {
        grid-template-columns: 1fr;
        grid-gap: 3rem;
      }

      .info {
        max-width: 480px;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3rem;

        @include md {
          grid-template-columns: 1fr;
          margin-bottom: 100px;
        }

        .first,
        .second {
          h3 {
            color: #006281;
            font-size: 30px;
            font-weight: 600;

            margin-bottom: 15px;

            font {
              color: #006281;
            }
          }

          span {
            display: inline-block;
            height: 1px;
            background: #006281;
            width: 35%;
            margin-bottom: 20px;

            font {
              color: #006281;
            }
          }

          .points {
            .top {
              min-height: 60px;

              h4 {
                color: #006281;
                font-weight: 400;
                font-size: 15px;

                font {
                  color: #006281;
                }
              }

              p {
                color: #006281;
                font-weight: 700;
                display: flex;
                margin-top: 10px;
                font-size: 15px;
                /* align-items: center; */
                gap: 5px;
                justify-content: start;

                svg {
                  fill: #006281;
                }

                font {
                  color: #006281;
                }
              }
            }

            .bottom {
              margin-top: 20px;

              h4 {
                color: #006281;
                font-weight: 400;
                font-size: 15px;

                font {
                  color: #006281;
                }
              }

              p {
                color: #006281;
                font-weight: 700;
                margin-top: 10px;
                font-size: 15px;

                font {
                  color: #006281;
                }
              }
            }
          }
        }
      }
    }
  }
}
