:root {
  --mc1: #cc00ff;
  --mc2: #7000ff;
  --hc: #ddd;
  --bc: #9ba0b8;
  --hff: "Heebo", sans-serif;
  --bff: "Nunito", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Coiny&family=Josefin+Sans:wght@300;400;500;600;700&family=Kanit:wght@400;500;600&family=Koulen&family=Libre+Bodoni:wght@400;500;600;700&family=Luckiest+Guy&family=Martel+Sans:wght@200;300;400;600;700;800;900&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,800;1,900&family=Oleo+Script:wght@400;700&family=Open+Sans:wght@300;400;500;600;700;800&family=Pacifico&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@400;500;600;700;800;900&family=Space+Grotesk:wght@300;400;500;600;700&family=Spectral:wght@200;300;400;500;600;700;800&family=Tapestry&family=VT323&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Staatliches&family=Yanone+Kaffeesatz:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://font.googleapis.com/css2?family=Fredoka+One&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bakbak+One&family=Catamaran:wght@100;200;300;400;500;600;700;800;900&family=Chakra+Petch:wght@300;400;500;600;700&family=DM+Sans:wght@400;500;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:wght@100;300;400;700;900&family=Manjari:wght@100;400;700&family=Montserrat:wght@200&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=PT+Mono&family=Paytone+One&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Space+Grotesk:wght@300;400;500;600;700&family=Space+Mono:wght@400;700&family=Syne:wght@400;500;600;700&display=swap");
:root {
  --wagmi: #ed003b;
  --checkSize: 2rem;
}
a {
  text-decoration: none !important;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: unset !important;
}
@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&family=Rubik:wght@300&display=swap");

/* font-family: 'Bakbak One',
cursive;
font-family: 'Catamaran',
sans-serif;
font-family: 'Chakra Petch',
sans-serif;
font-family: 'DM Sans',
sans-serif;
font-family: 'Inter',
sans-serif;
font-family: 'Lato',
sans-serif;
font-family: 'Manjari',
sans-serif;
font-family: 'Montserrat',
sans-serif;
font-family: 'Nunito',
sans-serif;
font-family: 'Paytone One',
sans-serif;
font-family: 'Poppins',
sans-serif;
font-family: 'PT Mono',
monospace;
font-family: 'Roboto',
sans-serif;
font-family: 'Space Grotesk',
sans-serif;
font-family: 'Space Mono',
monospace;
font-family: 'Syne',
sans-serif; */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-family: "DM Sans", sans-serif;
  text-decoration: none;
  color: #fff;
}
body {
  position: relative;
  min-height: 100%;
  top: 0px;
  overflow-x: hidden;
}
.Toastify__toast-body > div {
  color: #7d7d7d;
}
.Toastify__close-button > svg {
  fill: #7d7d7d;
  height: 16px;
  width: 14px;
}
/* body:before {
  content: "";
 background-color: #cc00ff;

  position: absolute;
  top: 0;
  left: 0;

} */
/* body{
   background: url(../assests/bg/body.png) no-repeat center
  center;
  background-size: cover;
  z-index: 111;
  width: 100%;
  height: 100%;
} */
.btn,
button span,
button {
}
@font-face {
  font-family: "Sans-pro";
  src: url(./font/Sans/SourceSansPro-Regular.ttf);
}

@font-face {
  font-family: "Kaulen";
  src: url(./font/AMOQz46as3KIBPemhXo8.woff2);
}

@font-face {
  font-family: "Proxima";
  src: url(./font/Proxima\ Nova\ Font.otf);
}
@font-face {
  font-family: "Barlow-medium";
  src: url(./font/BarlowCondensed-Medium.ttf);
}
@font-face {
  font-family: "Barlow-light";
  src: url(./font/BarlowCondensed-Light.ttf);
}
@font-face {
  font-family: "Barlow-bold";
  src: url(./font/BarlowCondensed-Bold.ttf);
}
@font-face {
  font-family: "Quarry Bones";
  src: url(./font/Quarry-Bones.otf);
}
@font-face {
  font-family: "BerkshireSwash";
  src: url(./font/new/BerkshireSwash-Regular.ttf);
}
@font-face {
  font-family: "BerkshireSwash";
  src: url(./font/new/BerkshireSwash-Regular.ttf);
}
@font-face {
  font-family: "BakbakOneRegular";
  src: url(./font/new/BakbakOneRegular.woff);
}

.top__banner {
  position: relative;
}

.mar > div {
  height: 80px;
  background: #fff;
}

.marquee {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  animation-play-state: var(--play);
  animation-delay: var(--delay);
  animation-direction: var(--direction);
  overflow: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bakbak One", cursive;
  font-family: "Barlow-bold";
  font-family: "Quarry Bones";
  font-family: "RocGrotesk3";
  font-family: "BerkshireSwash";
  text-shadow: none !important;
  font-family: "Raleway", Sans-serif;
  font-family: "Syne", sans-serif;
  font-family: "Raleway", Sans-serif;
  /* color: #f7ea66 !important; */
  /* background: #f7ea66 !important; */
}
.back {
  position: relative;
}

h1 {
  font-size: 4rem;
  text-transform: capitalize;
}
.heading {
  text-align: center;
}
button {
  border: 0;
  outline: none;
}
::before,
::after {
  box-sizing: border-box;
}

.bottomm {
  position: relative;
}

.bottomm::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.roadmap + .react-parallax {
  background: black;
}

.roadmap + .react-parallax > img {
  opacity: 0.2;
}

html {
  scroll-behavior: smooth;
}

button .active span {
}

.makeStyles-heading-2 {
  font-size: 18px !important;
  font-weight: 400;
}

@media (max-width: 570px) {
  button {
  }
}
.skiptranslate iframe {
  display: none;
}
