@import "./variable.scss";
@import "./media.scss";
#histry{
    background: #006281d1;
    .container{
        position: relative;
        max-width: 1200px;
        margin: auto;
        height: 100%;
        z-index: 6;
        display: flex;
        padding: 90px 15px 90px;
        /* margin-top: 230px; */
        flex-direction: column;
        align-items: start;
        justify-content: center;
        .heading {
            h1 {
                color: white;
                font-size: clamp(18%, 12vw, 4rem);
                text-align: start;
                font-weight: 600;
                margin-bottom: 30px;
            }
     
        }
        p{
            color: white;
            text-align: start;
            max-width: 900px;
            font-size: 22px;
            letter-spacing: 0.2px;
            line-height: 1.2em;
            margin-bottom: 30px;
            font-weight: 200;
        }

    }
}