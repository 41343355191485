@import "./variable.scss";
@import "./media.scss";

#car {
  .container {
    position: relative;
    max-width: 1200px;
    margin: auto;
    height: 100%;
    z-index: 6;
    display: flex;
    padding: 90px 15px 90px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .heading {
      margin-bottom: 30px;
      h1 {
        color: #006281;
        font-size: clamp(18%, 12vw, 4rem);
        text-align: start;
        text-transform: none;
        font-weight: 600;
      
        font {
          color: #006281;
        }
      }
    }

    .grid-box {
      display: grid;
      grid-template-columns: 1fr 2fr;

      @include md {
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: start;

      }

      .img {
        margin-right: 20px;
        @include md{
        margin-right: 0px;
        margin-bottom: 20px;
          
        }
        img {
          width: 100%;
          max-width: 300px;
          height: auto;
          object-fit: cover;
        }
      }

      .content {
        .para {
          background: #b2d0d9;
          display: flex;
          flex-direction: column;

          align-items: center;
          padding: 30px 20px 100px;
          justify-content: center;
          border-radius: 25px;

          p {
            color: black;
            text-align: start;
            font-size: 22px;
            letter-spacing: 0.2px;
            line-height: 1.2em;
            font-weight: 200;
            font {
              color: #000;
            }
          }
        }

        .btn {
          display: flex;
          align-items: center;
          margin-top: 40px;
          justify-content: start;

          @include smobile {
            margin-top: 30px;
          }

          a {
            border-radius: 30px;
            padding: 15px 20px;
            background: #006281;
            color: white;
            font-weight: 600;
            font-size: 26px;
            font {
              color: #fff;
            }
            @include lg {
              font-size: 20px;
            }

            // @include mobile{
            //   font-size: 18px;
            // }
            // @include smobile{
            //   font-size: 16px;
            // }
          }
        }
      }
    }
  }

  .container2 {
    font-family: Raleway, sans-serif;
    background: #006281;
    .parteners {
      max-width: 1200px;
      margin: auto;
      padding: 60px 15px;
      display: flex;
      flex-direction: column;

      h1 {
        color: #a2f6e3;
        font-size: clamp(18%, 10vw, 3.5rem);
        text-align: start;
        font-weight: 600;
margin-bottom: 50px;
        text-transform: none;
      }
      ul {
        display: grid;
        grid-template-columns: 0.6fr 1fr 1.5fr 1.5fr;
        align-items: center;
        padding: 15px;
        background: white;
        justify-content: space-between;
        @include lg {
          grid-template-columns: 1fr 1fr;

        }
        @include mobile {
          grid-template-columns: 1fr;
        }
        .main {
          display: flex;
          align-items: start;
          flex-direction: column;
          justify-content: center;
          img {
            width: 100%;
            max-width: 130px;
          }
        }
        li {
          list-style: none;
          font-size: 20px;
          font-weight: 800;
          color: #006281;
          font {
            color: #006281;
          }
        }
      }
    }
  }
}
