@import "./variable.scss";
@import "./media.scss";

footer {
  position: relative;
  background-color: #006281;

  >.container {
    margin: 0 auto;
    padding: 0px 20px 0;
    max-width: 1200px;

    .btn {
      display: flex;
      align-items: center;
      margin-bottom: 52px;
      width: 100%;
      justify-content: center;
      // margin: auto;

      a {
        border-radius: 30px;
        padding: 14px 22px;
        background: white;
        color: #006281;
        font-weight: 600;
        font-size: 25px;

        font {
          color: #006281;
        }
      }
    }

    .footer-top {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      flex-direction: column;
      padding: 90px 0px;

      @include lg {
        grid-template-columns: 1fr 1fr;
        grid-gap: 3rem 0;
      }

      @include mobile {
        grid-template-columns: 1fr;
      }

      .footer-logo {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;

        .icons {
          display: flex;
          justify-content: center;
          list-style: none;
          width: 100%;

          @include md {}

          >a {
            transition: 0.2s all;
            line-height: 1.5em;
            position: relative;
            z-index: auto;
            margin: 0 20px;

            background-position: center 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            border-radius: 4px;
            padding: 6px 0px;
            font-weight: 400;
            text-transform: capitalize;

            svg {
              font-size: 16px;
              fill: #fff;
            }
          }
        }

        .logo {
          height: 90px;
          display: flex;
          align-self: center;
          margin: auto;
          margin-bottom: 52px;

          img {
            height: 100%;
          }
        }

        .email {
          display: none;

          input {
            width: 100%;
            padding: 14px 15px;
            background-color: #f4f8fa;
            border-radius: 10px;
            color: #ffffff;
            border: 1px solid #ffffff20;
            margin: auto;
          }

          button {
            border: 0;
            font-family: "Rubik", sans-serif;
            background: #006281;
            transition: all 0.2s linear;
            color: #4069eb;
            padding: 14px 50px;
            display: flex;
            font-size: 16px;
            font-weight: 500;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            border-radius: 30px;
            padding: 18px 45px;
            padding: 14px 30px 16px;
            background-color: #5927e3;
            border-radius: 10px;
            box-shadow: 0px 4px 24px 0px rgba(89, 39, 227, 0.25);
            text-align: center;
            position: relative;
            overflow: hidden;
            padding: 14px 30px 16px;
            background-color: #006281;
            border-radius: 10px;
            box-shadow: 0px 4px 24px 0px rgba(89, 39, 227, 0.25);
            text-align: center;
            position: relative;
            overflow: hidden;
            color: #fff;
            margin-left: 10px;
          }
        }
      }

      .contact {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        flex-direction: column;

        p {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 15px;

          span {
            font-weight: 300;
            font-family: "DM Sans", sans-serif;
            color: #fff;
            margin-right: 10px;
          }

          a {
            color: #fff;
            font-size: 15px;
            font-weight: 300;
            font-family: "DM Sans", sans-serif;
          }
        }
      }

      .footbox {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .h {
          h3 {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            font-family: "DM Sans", sans-serif;
          }
        }

        .list {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          gap: 10px;

          a {
            color: #fff;
            font-size: 16px;
            font-weight: 300;
            font-family: "DM Sans", sans-serif;
          }
        }
      }
    }

    .bottom__footer {
      padding: 13px 0 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #ffffff20;

      @include md {
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        row-gap: 20px;
      }

      p {
        font-size: 16px;
        color: #fff;
        text-align: start;
      }


      .end {
        display: flex;
        align-items: center;
        justify-content: end;
        color: #fff;
        gap: 15px;

        @include md {
          justify-content: center;
        }

        a {
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }
}

.join__community {
  position: relative;
  padding: 120px 10px;
  background-image: url("https://stakify.io/assets/images/community-bg.png");
  background-size: cover;
  background-position: center center;
  background-size: 120%;
  background-position: top center;
  background-repeat: no-repeat;

  .container {
    max-width: 1200px;
    margin: auto;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -55px;
      left: 0;
      width: 100%;
      height: -webkit-fill-available;
      background: url(https://stakify.io/assets/images/dot-line.png);
      background-size: contain;
      background-position: bottom;
      background-repeat: no-repeat;
      transform: scale(1.05);
    }

    .start {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      max-width: 600px;
      margin: 0 auto 40px;

      span {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        color: #4e0dff;
        font-style: normal;
        text-align: center;
        margin-bottom: 10px;

        @include mobile {
          margin: 10px 0px;
        }
      }

      h1 {
        font-size: 57px;
        line-height: 74px;
        font-weight: 800;
        color: #13216e;
        font-style: normal;
        text-align: center;

        @include md {
          line-height: 78px;
          font-size: 70px;
        }

        @include mobile {
          line-height: 74px;
          font-size: 60px;
        }

        @media (max-width: 424px) {
          line-height: 68px;

          font-size: 55px;
        }

        @media (max-width: 390px) {
          line-height: 65px;

          font-size: 48px;
        }

        @media (max-width: 340px) {
          line-height: 58px;

          font-size: 46px;
        }

        span {
          color: #4e0dff;
        }
      }

      p {
        margin: 15px 0px;
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
        color: #13216e;
        font-style: normal;
        max-width: 430px;
        text-align: center;
        font-family: "Rubik", sans-serif;
        margin: 15px auto;

        @include mobile {
          margin: 15px 0px;
        }

        b {
          color: #13216e;
          font-family: "Rubik", sans-serif;
        }

        @include md {
          text-align: center;
          font-size: 16px;
          text-align: start;
        }
      }
    }

    .end {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: relative;
      -webkit-box-shadow: 0px 4px 24px 0px rgba(19, 33, 110, 0.25);
      box-shadow: 0px 4px 24px 0px rgba(19, 33, 110, 0.25);
      border-radius: 20px;
      background: #ffffff;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        margin-top: -2px;
        margin-left: -2px;
      }
    }
  }
}