@import "./variable.scss";
@import "./media.scss";

#quality {
  .container {
    position: relative;
    max-width: 1200px;
    margin: auto;
    height: 100%;
    z-index: 6;
    display: flex;

    padding: 90px 15px 90px;
    /* margin-top: 230px; */
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .heading {
      text-align: start;
      width: 100%;
      margin-bottom: 30px;
      h1 {
        color: #006281;
        font-size: clamp(18%, 12vw, 4rem);
        text-align: start;
        font-weight: 600;
        text-transform: none;
        font {
          color: #006281;
        }
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      @include md {
        display: block;
      }
      .img {
        position: relative;
        height: 100%;
        margin-right: 10px;
        @include md {
          max-width: 200px;
        }
        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100% !important;
        //     height: 100%;
        //     background-image: url(http://localhost:3000/static/media/icon.74ea75e84c8c071c6a50.png);
        //     background-size: contain;
        //     z-index: 11;
        //     display: flex;
        //     background-repeat: no-repeat;
        //     align-items: center;
        //     justify-content: center;
        // }
        .im-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
 
          position: absolute;
          top: 0;

          img {
            &:nth-child(1){
              margin-bottom: 15px;
            }
            width: 100%;
            height: auto;
            max-width: 80px;
          }
        }
      }
      .box {
        background: #b2d0d9;
        display: flex;
        flex-direction: column;
   
        align-items: start;
        padding: 30px 20px 30px;
        justify-content: center;
        border-radius: 25px;
   
        p {
          color: black;
          text-align: start;
          font-size: 22px;
          letter-spacing: 0.2px;
          line-height: 1.2em;
          font-weight: 200;
          &:nth-child(1){
            margin-bottom: 15px;
                    }
          font {
            color: black;
          }
        }
      }
    }
  }
}
